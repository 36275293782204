import { useState, useContext } from 'react';
import { useCookie } from 'next-cookie';
import { Modal, ThemeContext } from '@forma/forma-ui-kit';
import ClientWrap from '@/components/ClientWrap';
import { SharedContext } from '@/context/SharedContext';
import { analytics } from '@/helpers/analytics';
import { ModalTitle, ModalContent } from './elements';
import { useRequestDemo } from './useRequestDemo';

export const RequestDemoModal = () => {
  const cookie = useCookie();
  const { lang } = useContext(ThemeContext);
  const { state, dispatch: dispatchContext } = useContext(SharedContext);
  const [ isFormValid, setFormValid ] = useState(false);

  const {
    onSubmit,
    saveResult,
    isSuccess,
    isError,
    reset
  } = useRequestDemo({ lang, roistatVisit: cookie.get('roistat_visit') });

  const open = !!state.requestModal;
  const closeModal = () => {
    reset();
    dispatchContext({ type: 'REMOVE', payload: 'requestModal' });
  };

  const handleSubmit = (values) => {
    onSubmit(values).then(success => {
      if (success) {
        analytics.sendRequestFullForm();
      }
    });;
  };

  return (
    <ClientWrap>
      <Modal
        open={open}
        onClose={closeModal}
        size="large"
        title={<ModalTitle isSuccess={isSuccess} isError={isError} saveResult={saveResult} />}
      >
        <ModalContent
          onSubmit={handleSubmit}
          onChangeValid={setFormValid}
          isFormValid={isFormValid}
          isSuccess={isSuccess}
          isError={isError}
          onClose={closeModal}
        />
      </Modal>
    </ClientWrap>
  );
};

export default RequestDemoModal;
