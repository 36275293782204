import { useContext } from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { Button } from '@forma/forma-ui-kit';
import { SharedContext } from '@/context/SharedContext';
import { analytics } from '@/helpers/analytics';

const RequestDemoButton = ({ children, className, viewStyle, onClick, ...props }) => {
  const { t } = useTranslation();
  const { dispatch: dispatchContext } = useContext(SharedContext);

  const handleClickButton = (e) => {
    e.preventDefault();
    analytics.clickTryTrial();
    dispatchContext({ type: 'ADD', payload: { requestModal: true } });
    if (onClick) onClick(e);
  };

  return (
    <Button
      {...props}
      as={Link}
      className={className}
      onClick={handleClickButton}
      viewStyle={viewStyle ?? 'primary'}
      href="/request"
    >
      {children ?? t('request_demo')}
    </Button>
  );
};

export default RequestDemoButton;
