import { useState, useContext } from 'react';
import { useTranslation, Trans } from 'next-i18next';
import { useCookie } from 'next-cookie';
import moment from 'moment';
import { Button, ThemeContext } from '@forma/forma-ui-kit';
import Link from '@/components/Link';
import { analytics } from '@/helpers/analytics';
import { RequestDemoForm } from './elements';
import { useRequestDemo } from './useRequestDemo';

import styles from './request-demo.module.css';

const RequestDemo = () => {
  const cookie = useCookie();
  const { t } = useTranslation();
  const { lang } = useContext(ThemeContext);
  const [ isFormValid, setFormValid ] = useState(false);

  const {
    onSubmit,
    saveResult,
    isSuccess,
    isError
  } = useRequestDemo({ lang, roistatVisit: cookie.get('roistat_visit') });

  const handleSubmit = (values) => {
    onSubmit(values).then(success => {
      if (success) {
        window.scrollTo({ top: 0, left: 0 });
        analytics.sendRequestFullForm();
      }
    });
  };

  if (isSuccess) return (
    <div className={styles.root}>
      <div className={styles.result}>
        <div className={styles.resultTitle}>
          <Trans
            i18nKey="you_signed_up_for_demonstration"
            components={{ accent: <span className="accent-text" /> }}
          />
          <br />
          <div className="accent-text">
            {moment(saveResult.date).utcOffset(saveResult.utc).format('DD.MM.yyyy')} {t('year_short')} {t('at')} {moment(saveResult.date).utcOffset(saveResult.utc).format('HH:mm')}
          </div>
        </div>
        <div className={styles.resultSubtitle}>{t('demonstration_time_notice')}</div>
        <div className={styles.resultSubtitle}>{t('demonstration_description')}</div>
        <div className={styles.buttons}>
          <Button
            as={Link}
            className={styles.button}
            viewStyle="primary"
            size="medium"
            href="/"
          >
            {t('go_to_homepage')}
          </Button>
        </div>
      </div>
    </div>
  );

  if (isError) return (
    <div className={styles.root}>
      <div className={styles.result}>
        <div className={styles.resultTitle}>
          {t('contacts_form_send_error')}
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.root}>
      <h1 className={styles.title} id="request_title">
        <Trans
          i18nKey="sign_up_presentation"
          components={{ accent: <span className="accent-text" /> }}
        />
      </h1>
      <div className={styles.subtitle}>
        {t('sign_up_presentation_description')}
      </div>

      <RequestDemoForm onSubmit={handleSubmit} onChangeValid={setFormValid} />

      <div className={styles.buttons}>
        <Button
          type="submit"
          form="request_demo_form"
          className={styles.button}
          viewStyle="primary"
          size="medium"
          disabled={!isFormValid}
        >
          {t('sign_up_demo')}
        </Button>
      </div>
      <div className={styles.notice}>
        <Trans
          i18nKey="user_agreement_form_notice"
          components={{ privacy: <Link href="/privacy" />, license: <Link href="/license" />, button: t('sign_up_demo') }}
        />
      </div>
    </div>
  );
};

export default RequestDemo;
