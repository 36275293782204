import { useTranslation, Trans } from 'next-i18next';
import { Button } from '@forma/forma-ui-kit';
import Link from '@/components/Link';
import RequestDemoForm from './RequestDemoForm';

import styles from '../request-demo.module.css';

const ModalContent = ({
  onSubmit, onChangeValid, isFormValid, isSuccess, isError, onClose
}) => {
  const { t } = useTranslation();

  if (isSuccess) return (
    <div className={styles.modal}>
      <div className={styles.resultSubtitle}>{t('demonstration_time_notice')}</div>
      <div className={styles.resultSubtitle}>{t('demonstration_description')}</div>
      <div className={styles.buttons}>
        <Button
          className={styles.button}
          viewStyle="primary"
          size="medium"
          onClick={onClose}
        >
          {t('uikit:done')}
        </Button>
      </div>
    </div>
  );

  if (isError) return null;

  return (
    <div className={styles.modal}>
      <div className={styles.modalSubtitle}>
        {t('sign_up_presentation_description')}
      </div>
      <div className={styles.modal}>
        <RequestDemoForm onSubmit={onSubmit} onChangeValid={onChangeValid} />
      </div>
      <div className={styles.buttons}>
        <Button
          type="submit"
          form="request_demo_form"
          className={styles.button}
          viewStyle="primary"
          size="medium"
          disabled={!isFormValid}
        >
          {t('sign_up_demo')}
        </Button>
      </div>
      <div className={styles.notice}>
        <Trans
          i18nKey="user_agreement_form_notice"
          components={{ privacy: <Link href="/privacy" />, license: <Link href="/license" />, button: t('sign_up_demo') }}
        />
      </div>
    </div>
  );
};

export default ModalContent;
