import { useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';
import { phonemasks, Input, PhoneInput, SocialSelect } from '@forma/forma-ui-kit';
import ControlField from '@/components/fields/ControlField';
import DateTimeInput from '@/components/fields/DateTimeInput';
import { queries } from '@/helpers/api';

import styles from '../request-demo.module.css';

const RequestDemoForm = ({ onSubmit, onChangeValid }) => {
  const { t, i18n } = useTranslation();
  const [ phoneValidation, setPhoneValidation ] = useState('');
  const [ timeslots, setTimeslots ] = useState(null);

  const { register, handleSubmit, control, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    defaultValues: {
      social: 'Звонок'
    }
  });

  useEffect(() => {
    if (i18n && phonemasks) {
      const code = i18n.language === 'en' ? 'US' : i18n.language.toUpperCase();
      const mask = phonemasks.find(({ iso }) => iso === code);
      if (mask) setPhoneValidation(mask.code + mask.mask);
    }
  }, [i18n]);

  useEffect(() => {
    const getTimeslots = async () => {
      const res = await queries.getTimeslots();
      setTimeslots(res, i18n.language);
    };
    getTimeslots();
  }, [i18n.language]);

  useEffect(() => {
    onChangeValid(isValid);
    // eslint-disable-next-line
  }, [isValid]);

  const handleChangeMask = ({ mask, code }) => {
    setPhoneValidation(code + mask);
  };

  return (
    <form id="request_demo_form" className={styles.fields} onSubmit={handleSubmit(onSubmit)}> {/* id for submit button */}
      <ControlField
        id="name"
        component={Input}
        name="name"
        placeholder={t('your_name')}
        control={control}
        {...register('name', {
          required: t('please_fill_the_field'),
          minLength: { value: 3, message: t('errors.min_lenght_3') },
          pattern: { value: /^[^0-9]+$/, message: t('errors.name_has_numbers') }
        })}
        meta={{
          error: errors.name,
          errorMessage: errors.name?.message,
          containerClass: styles.inputContainer
        }}
      />
      {/* <ControlField
        id="email"
        component={Input}
        name="email"
        placeholder={t('email')}
        control={control}
        {...register('email', {
          required: t('please_fill_the_field'),
          minLength: { value: 3, message: t('errors.min_lenght_3') },
          pattern: { value: /\S+@\S+\.\S+/, message: t('errors.email_not_valid') }
        })}
        meta={{
          error: errors.email,
          errorMessage: errors.email?.message,
          containerClass: styles.inputContainer
        }}
      /> */}
      {/* <ControlField
        id="company_name"
        component={Input}
        name="company_name"
        placeholder={t('yours_company_name')}
        control={control}
        {...register('company_name', {
          required: t('please_fill_the_field'),
          minLength: { value: 3, message: t('errors.min_lenght_3') }
        })}
        meta={{
          error: errors.company_name,
          errorMessage: errors.company_name?.message,
          containerClass: styles.inputContainer
        }}
      /> */}
      <ControlField
        id="date_time"
        component={DateTimeInput}
        name="date_time"
        placeholder={t('select_date_time_for_presentation')}
        control={control}
        error={errors.date_time}
        errorMessage={errors.date_time?.message}
        {...register('date_time', {
          required: t('please_fill_the_field'),
          validate: {
            checkSlot: value => !!value.slot || t('errors.select_slot'),
          }
        })}
        containerClass={styles.inputContainer}
        timeslots={timeslots}
      />
      <ControlField
        id="phone"
        component={PhoneInput}
        masks={phonemasks}
        defaultMaskCode={i18n.language === 'en' ? 'US' : i18n.language.toUpperCase()}
        onChangeMask={handleChangeMask}
        name="phone"
        placeholder={t('phone_number')}
        control={control}
        error={errors.phone}
        errorMessage={errors.phone?.message}
        {...register('phone', {
          required: t('please_fill_the_field'),
          minLength: { value: phoneValidation.length, message: t('errors.min_lenght_phone') }
        })}
        autoComplete="off"
        containerClass={styles.inputContainer}
        className={styles.phoneInputInput}
      />
      <ControlField
        id="social"
        component={SocialSelect}
        name="social"
        label={t('where_contact_you_presentation')}
        control={control}
        error={errors.social}
        errorMessage={errors.social?.message}
        {...register('social', { required: t('please_fill_the_field') })}
        containerClass={styles.inputContainer}
      />
    </form>
  );
};

export default RequestDemoForm;
