import { useTranslation, Trans } from 'next-i18next';
import moment from 'moment';

const ModalTitle = ({ isSuccess, isError, saveResult }) => {
  const { t } = useTranslation();

  if (isSuccess) return (
    <div>
      <Trans
        i18nKey="you_signed_up_for_demonstration"
        components={{ accent: <span className="accent-text" /> }}
      />

      <div className="accent-text">
        {moment(saveResult.date).utcOffset(saveResult.utc).format('DD.MM.yyyy')} {t('year_short')} {t('at')} {moment(saveResult.date).utcOffset(saveResult.utc).format('HH:mm')}
      </div>
    </div>
  );

  if (isError) return (
    <Trans i18nKey="contacts_form_send_error" />
  );

  return (
    <Trans
      i18nKey="sign_up_presentation"
      components={{ accent: <span className="accent-text" /> }}
    />
  );
};

export default ModalTitle;
